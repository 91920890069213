
  import { defineComponent } from 'vue';
  import {getRevised} from "@/utils/revised";

  export default defineComponent({
    name: 'About',
    computed: {
      revised() {
        return getRevised(document);
      }
    }
  });
