import axios from "axios";

export function getRevised(element: Document | HTMLElement | null) {
    if (!element) {
        return null;
    }
    const revisedMeta = element.querySelector('meta[name="revised"]');
    if (!revisedMeta) {
        return null;
    }
    return revisedMeta.getAttribute('content');
}

export async function checkRevised(): Promise<boolean> {
    const preRevised = getRevised(document);
    if (!preRevised) {
        return false;
    }
    const timestamp = new Date().getTime();
    const queryUrl = `/?timestamp=${timestamp}`;
    const response = await axios.get(queryUrl);
    const el = document.createElement("div");
    document.body.appendChild(el);
    el.setAttribute('hidden', 'true');
    el.innerHTML = response.data;
    const revised = getRevised(el);
    document.body.removeChild(el);
    return revised != preRevised;
}