import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06571e73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about" }
const _hoisted_2 = { style: {"width":"100px","margin":"0 auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " 版本：" + _toDisplayString(_ctx.revised), 1)
  ]))
}